import React, {useState, useEffect} from "react";
import { Box } from "@sqymagma/elements";

export default ({alt = "banner buscando datos", num = "random" }) => {


  const [chosenNum, setChosenNum] = useState(null)
  useEffect(() => {
    if(isNaN(+num)) {
      setChosenNum(Math.ceil(Math.random() * 22))
    } else {
      setChosenNum(num)
    }
  }, [num])


  return (
    chosenNum && (
      <Box width={1} textAlign="center" my="m">
      <a
        href="mailto:coronavirus.secuoyas.com?subject=Feedback%20from%20web"
        target="_blank" rel="noreferrer"
      >
        <img src={`/images/svg/buscandodatos/buscando-datos-${chosenNum}.svg`} alt={alt} />
      </a>
    </Box>
    )
  );
};
