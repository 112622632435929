import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongWhite } from "../../../components/Textstyles/Styles";

export const frontmatter = {
  title: "Día 79",
  week: "Semana 12",
  day: "31",
  month: "May",
  monthNumber: "05",
  date: "2020-05-31",
  path: "/cronologia/semana-12#dia-31-may/",
};

const Day79 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Quinto día del periodo de luto oficial
        </ModDailyHighlight>
        <ModText>
          En los últimos siete días se han diagnosticado 2,875 casos mediante
          pruebas PCR. En ese mismo periodo, 263 personas han requerido
          hospitalización (9,14% de los diagnósticos), de los que nueve han sido
          ingresos en la UCI.
        </ModText>

        <ModDailyHighlight>
          El gobierno busca apoyos para extender el estado de alarma una{" "}
          <StrongWhite>sexta y última prórroga</StrongWhite>.
        </ModDailyHighlight>
        <ModText>
          El Ministerio de Sanidad informa que las comunidades autónomas han
          llevado a cabo un total de 2.536.234 pruebas diagnósticas PCR desde
          que comenzó la pandemia por COVID-19 hasta el 28 de mayo, de las
          cuales 314.737 fueron realizadas en la última semana.
        </ModText>
        <ModImage
          src="/images/svg/07_abr_ensayos_CarlosIII.svg"
          alt="AtributoAlt"
        />

        <ModBuscandoDatos num="random" />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day79;
